var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "vx-card",
        [
          _c(
            "div",
            { staticClass: "flex flex-col md:flex-row gap-x-4" },
            [
              _c("div", { staticClass: "flex-grow contentzone" }, [
                _c(
                  "div",
                  { staticClass: "relative" },
                  [
                    _vm.options &&
                    _vm.isProctoringRecord(_vm.classroomUser.record_medium)
                      ? _c("Player", {
                          ref: "playerComponent",
                          attrs: { options: _vm.options },
                        })
                      : _c("edu-placeholder-action", {
                          staticClass: "z-50 bottom-0 relative p-6",
                          attrs: {
                            label: _vm.$t("nao-contem-video"),
                            noAction: true,
                          },
                        }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "permission",
                        rawName: "v-permission",
                        value: "proctoring.audit.add_comment",
                        expression: "'proctoring.audit.add_comment'",
                      },
                    ],
                    staticClass: "pt-4 pb-10",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "w-full flex gap-2 justify-end" },
                      [
                        !_vm.showComment
                          ? _c(
                              "vs-button",
                              {
                                on: {
                                  click: function ($event) {
                                    _vm.showComment = true
                                    _vm.scrollToBotton()
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("adicionar-avaliacao")) +
                                    " "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm.showComment
                      ? _c("div", {}, [
                          _c(
                            "div",
                            { staticClass: "w-full" },
                            [
                              _c("ComplexEditor", {
                                staticClass:
                                  "break-words overflow-y-auto overflow-x-hidden",
                                staticStyle: {
                                  "max-height": "200px",
                                  "max-width": "700px",
                                },
                                attrs: {
                                  editor_data: _vm.commentData,
                                  placeholder: _vm.$t(
                                    "informe-um-comentario-aqui"
                                  ),
                                },
                                on: {
                                  "update:editor_data": function ($event) {
                                    _vm.commentData = $event
                                  },
                                  "complex-editor-is-open": _vm.editorIsOpen,
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "flex items-center p-2 flex-wrap gap-4",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "gap-2" },
                                [
                                  _c(
                                    "vs-checkbox",
                                    {
                                      directives: [
                                        {
                                          name: "permission",
                                          rawName: "v-permission",
                                          value:
                                            "proctoring.audit.add_comment_time_video",
                                          expression:
                                            "'proctoring.audit.add_comment_time_video'",
                                        },
                                      ],
                                      staticClass: "mb-3",
                                      model: {
                                        value: _vm.isTimeVideo,
                                        callback: function ($$v) {
                                          _vm.isTimeVideo = $$v
                                        },
                                        expression: "isTimeVideo",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("evento-horario-video"))
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c("div", { staticClass: "gap-2" }, [
                                _c(
                                  "div",
                                  { staticClass: "flex gap-2 flex-grow-0" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        class: _vm.severityFilterVisuals(
                                          "L",
                                          "black"
                                        ),
                                        on: {
                                          click: function ($event) {
                                            return _vm.filterSeverity("L")
                                          },
                                        },
                                      },
                                      [
                                        _c("font-awesome-icon", {
                                          staticClass: "h-4 w-4 text-success",
                                          attrs: { icon: "exclamation" },
                                        }),
                                        _c(
                                          "span",
                                          { staticClass: "text-success" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("proctoring.criticity.L")
                                              )
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        class: _vm.severityFilterVisuals(
                                          "M",
                                          "black"
                                        ),
                                        on: {
                                          click: function ($event) {
                                            return _vm.filterSeverity("M")
                                          },
                                        },
                                      },
                                      [
                                        _c("font-awesome-icon", {
                                          staticClass: "h-4 w-4 text-warning",
                                          attrs: { icon: "exclamation-circle" },
                                        }),
                                        _c(
                                          "span",
                                          { staticClass: "text-warning" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("proctoring.criticity.M")
                                              )
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        class: _vm.severityFilterVisuals(
                                          "H",
                                          "black"
                                        ),
                                        on: {
                                          click: function ($event) {
                                            return _vm.filterSeverity("H")
                                          },
                                        },
                                      },
                                      [
                                        _c("font-awesome-icon", {
                                          staticClass: "h-4 w-4 text-danger",
                                          attrs: {
                                            icon: "exclamation-triangle",
                                          },
                                        }),
                                        _c(
                                          "span",
                                          { staticClass: "text-danger" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("proctoring.criticity.H")
                                              )
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "w-full flex gap-2 pt-2 justify-end",
                            },
                            [
                              _c(
                                "vs-button",
                                {
                                  staticClass: "float-right mt-2",
                                  on: {
                                    click: function ($event) {
                                      return _vm.addComment()
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("action.save")))]
                              ),
                              _c(
                                "vs-button",
                                {
                                  staticClass: "float-right mt-2",
                                  attrs: { type: "border" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.cancelComment()
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("action.cancel")))]
                              ),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                  ]
                ),
                !_vm.showComment
                  ? _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "permission",
                            rawName: "v-permission",
                            value: "proctoring.audit.allows_auditing",
                            expression: "'proctoring.audit.allows_auditing'",
                          },
                        ],
                        staticClass: "pt-4 pb-10",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "w-full" },
                          [
                            _c("ComplexEditor", {
                              staticClass:
                                "break-words overflow-y-auto overflow-x-hidden",
                              staticStyle: {
                                "max-height": "200px",
                                "max-width": "700px",
                              },
                              attrs: {
                                editor_data: _vm.audit_comment,
                                placeholder: _vm.$t(
                                  "informe-uma-observacao-auditoria-aqui"
                                ),
                              },
                              on: {
                                "update:editor_data": function ($event) {
                                  _vm.audit_comment = $event
                                },
                                "complex-editor-is-open": _vm.editorIsOpen,
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "pt-4 pb-10 w-full flex gap-2 justify-end",
                          },
                          [
                            _c(
                              "vs-button",
                              {
                                directives: [
                                  {
                                    name: "permission",
                                    rawName: "v-permission",
                                    value: "proctoring.audit.handling",
                                    expression: "'proctoring.audit.handling'",
                                  },
                                ],
                                staticClass: "btn-primary mr-2",
                                on: {
                                  click: function ($event) {
                                    return _vm.updateHandling("IH")
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("action.in_audit")))]
                            ),
                            _c(
                              "vs-button",
                              {
                                directives: [
                                  {
                                    name: "permission",
                                    rawName: "v-permission",
                                    value: "proctoring.audit.pending",
                                    expression: "'proctoring.audit.pending'",
                                  },
                                ],
                                staticClass: "btn-primary mr-2",
                                attrs: { color: "warning" },
                                on: {
                                  click: function ($event) {
                                    return _vm.updateHandling("PE")
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("action.pending")))]
                            ),
                            _c(
                              "vs-button",
                              {
                                directives: [
                                  {
                                    name: "permission",
                                    rawName: "v-permission",
                                    value: "proctoring.audit.deferred",
                                    expression: "'proctoring.audit.deferred'",
                                  },
                                ],
                                staticClass: "mr-2",
                                attrs: { color: "success" },
                                on: {
                                  click: function ($event) {
                                    return _vm.updateHandling("DE")
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("action.deferred")))]
                            ),
                            _c(
                              "vs-button",
                              {
                                directives: [
                                  {
                                    name: "permission",
                                    rawName: "v-permission",
                                    value: "proctoring.audit.reject",
                                    expression: "'proctoring.audit.reject'",
                                  },
                                ],
                                staticClass: "mr-2",
                                attrs: { color: "danger" },
                                on: {
                                  click: function ($event) {
                                    return _vm.updateHandling("RE")
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("action.reject_")))]
                            ),
                          ],
                          1
                        ),
                      ]
                    )
                  : _vm._e(),
              ]),
              _c("vs-divider", { staticClass: "md:hidden block mt-8 mb-2" }),
              _c(
                "div",
                { staticClass: "w-full md:w-4/12 lg:w-4/12 2xl:w-1/5" },
                [
                  _c("vs-card", { staticClass: "shadow-drop" }, [
                    _c(
                      "div",
                      { staticClass: "grid gap-x-1 grid-rows-3 auto-cols-min" },
                      [
                        _c("img", {
                          staticClass: "row-span-3 col-span-1 cursor-pointer",
                          attrs: {
                            src: _vm.proctoringPhoto,
                            width: "50px;",
                            height: "50px;",
                          },
                          on: {
                            click: function ($event) {
                              _vm.showingPhoto = true
                            },
                          },
                        }),
                        _c(
                          "label",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip.left",
                                value: {
                                  content: _vm.userName,
                                  delay: { show: 100, hide: 100 },
                                },
                                expression:
                                  "{\n              content: userName,\n              delay: { show: 100, hide: 100 }\n            }",
                                modifiers: { left: true },
                              },
                            ],
                            staticClass:
                              "col-start-2 row-span-1 text-sm font-bold self-end w-64 sm:w-auto md:w-48 lg:w-64 truncate",
                          },
                          [_vm._v(" " + _vm._s(_vm.userName) + " ")]
                        ),
                        _c(
                          "label",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip.left",
                                value: {
                                  content: _vm.userEmail,
                                  delay: { show: 100, hide: 100 },
                                },
                                expression:
                                  "{\n              content: userEmail,\n              delay: { show: 100, hide: 100 }\n            }",
                                modifiers: { left: true },
                              },
                            ],
                            staticClass:
                              "col-start-2 row-span-1 self-start w-64 sm:w-auto md:w-48 lg:w-64 truncate",
                          },
                          [_vm._v(" " + _vm._s(_vm.userEmail) + " ")]
                        ),
                      ]
                    ),
                    _c("div", { staticClass: "grid grid-cols-2" }, [
                      _c("span", { staticClass: "col-span-2" }, [
                        _vm._v(" " + _vm._s(_vm.$t("questionario")) + ": "),
                        _c("label", { staticClass: "font-bold" }, [
                          _vm._v(
                            _vm._s(
                              _vm.classroomUser &&
                                _vm.classroomUser.classroom.config.questionnaire
                                  .name
                            )
                          ),
                        ]),
                      ]),
                      _vm.classroomUser && _vm.classroomUser.answer
                        ? _c(
                            "span",
                            { staticClass: "col-span-2 md:col-span-1" },
                            [
                              _vm._v(" " + _vm._s(_vm.$t("inicio")) + ": "),
                              _c("label", { staticClass: "font-bold" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.classroomUser.answer.display_created_at
                                  )
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _vm.classroomUser && _vm.classroomUser.answer
                        ? _c(
                            "span",
                            { staticClass: "col-span-2 md:col-span-1" },
                            [
                              _vm._v(" " + _vm._s(_vm.$t("fim")) + ": "),
                              _c("label", { staticClass: "font-bold" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$utils.format.date(
                                      _vm.classroomUser.answer.finished_at
                                    )
                                  )
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _vm.classroomUser && _vm.classroomUser.audit_status
                        ? _c(
                            "span",
                            {
                              staticClass:
                                "flex items-center col-span-2 md:col-span-1",
                            },
                            [
                              _vm._v(" " + _vm._s(_vm.$t("status")) + ": "),
                              _c(
                                "vs-chip",
                                {
                                  staticClass: "text-sm text-center",
                                  attrs: {
                                    transparent: "",
                                    color: _vm.classroomUser.audit_status.color,
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.classroomUser.audit_status.label)
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]),
                  ]),
                  _vm.events.length > 0
                    ? _c(
                        "vs-tabs",
                        [
                          _c(
                            "vs-tab",
                            {
                              staticClass: "overflow-x-hidden overflow-y-auto",
                              staticStyle: { height: "470px" },
                              attrs: { label: _vm.$t("eventos") },
                            },
                            [
                              _vm.noEvents
                                ? _c("edu-placeholder-action", {
                                    attrs: {
                                      label: _vm.$t("ainda-nao-ha-eventos"),
                                    },
                                    on: { action: _vm.reload },
                                  })
                                : _vm._e(),
                              _c("proctoring-classroom-event-list", {
                                attrs: {
                                  events: _vm.events,
                                  showFilter: true,
                                  showSeverityFilter: true,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _c("edu-placeholder-action", {
                        staticStyle: { height: "264px" },
                        attrs: {
                          noAction: true,
                          label: _vm.$t("nenhum-evento-foi-registrado"),
                        },
                      }),
                ],
                1
              ),
            ],
            1
          ),
          _c("proctoring-image-modal", {
            attrs: { src: _vm.proctoringPhoto, visible: _vm.showingPhoto },
            on: {
              closed: function ($event) {
                _vm.showingPhoto = false
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }