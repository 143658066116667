<template>
  <div>
    <!--
  <div class="mb-4">
    <div>
      <vs-button
        type="border"
        @click="$router.back()"
        icon="undo"
      >{{ $t('common.back') }}</vs-button>
    </div>
  </div>
  -->
  <vx-card>
    <div class="flex flex-col md:flex-row gap-x-4">
      <div class="flex-grow contentzone">

        <div class="relative">
          <Player
            ref="playerComponent"
            v-if="options && isProctoringRecord(classroomUser.record_medium)"
            :options="options"/>

          <edu-placeholder-action
            v-else
            :label="$t('nao-contem-video')"
            :noAction="true"
            class="z-50 bottom-0 relative p-6"/>
        </div>

        <div class="pt-4 pb-10" v-permission="'proctoring.audit.add_comment'">
          <div class="w-full flex gap-2 justify-end">
            <vs-button v-if="!showComment" @click="showComment = true; scrollToBotton()">
              {{ $t('adicionar-avaliacao') }}
            </vs-button>
          </div>
          <div class="" v-if="showComment">
            <div class="w-full">
              <ComplexEditor
                v-bind:editor_data.sync="commentData"
                :placeholder="$t('informe-um-comentario-aqui')"
                class="break-words overflow-y-auto overflow-x-hidden"
                style="max-height:200px;max-width:700px;"
                @complex-editor-is-open="editorIsOpen"></ComplexEditor>
            </div>
            <div class="flex items-center p-2 flex-wrap gap-4">
              <div class="gap-2">
                <vs-checkbox v-permission="'proctoring.audit.add_comment_time_video'" v-model="isTimeVideo" class="mb-3">{{ $t('evento-horario-video') }}</vs-checkbox>
              </div>
              <div class="gap-2">
                <div class="flex gap-2 flex-grow-0">
                  <div @click="filterSeverity('L')" :class="severityFilterVisuals('L', 'black')">
                    <font-awesome-icon icon="exclamation" class="h-4 w-4 text-success"/>
                    <span class="text-success">{{ $t('proctoring.criticity.L') }}</span>
                  </div>
                  <div @click="filterSeverity('M')" :class="severityFilterVisuals('M', 'black')">
                    <font-awesome-icon icon="exclamation-circle" class="h-4 w-4 text-warning"/>
                    <span class="text-warning">{{ $t('proctoring.criticity.M') }}</span>
                  </div>
                  <div @click="filterSeverity('H')" :class="severityFilterVisuals('H', 'black')">
                    <font-awesome-icon icon="exclamation-triangle" class="h-4 w-4 text-danger"/>
                    <span class="text-danger">{{ $t('proctoring.criticity.H') }}</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="w-full flex gap-2 pt-2 justify-end">
              <vs-button
                class="float-right mt-2"
                @click="addComment()">{{ $t('action.save') }}</vs-button>
              <vs-button
                type="border"
                class="float-right mt-2"
                @click="cancelComment()">{{ $t('action.cancel') }}</vs-button>
            </div>
          </div>
        </div>

        <div class="pt-4 pb-10" v-permission="'proctoring.audit.allows_auditing'" v-if="!showComment">
          <div class="w-full">
            <ComplexEditor
              v-bind:editor_data.sync="audit_comment"
              :placeholder="$t('informe-uma-observacao-auditoria-aqui')"
              class="break-words overflow-y-auto overflow-x-hidden"
              style="max-height:200px;max-width:700px;"
              @complex-editor-is-open="editorIsOpen"></ComplexEditor>
          </div>
          <div class="pt-4 pb-10 w-full flex gap-2 justify-end">
            <vs-button
              v-permission="'proctoring.audit.handling'"
              class="btn-primary mr-2"
              @click="updateHandling('IH')">{{ $t('action.in_audit') }}</vs-button>
            <vs-button
                v-permission="'proctoring.audit.pending'"
                class="btn-primary mr-2"
                color="warning"
                @click="updateHandling('PE')">{{ $t('action.pending') }}</vs-button>
            <vs-button
                v-permission="'proctoring.audit.deferred'"
                class="mr-2"
                color="success"
                @click="updateHandling('DE')">{{ $t('action.deferred') }}</vs-button>
              <vs-button
                v-permission="'proctoring.audit.reject'"
                class="mr-2"
                color="danger"
                @click="updateHandling('RE')">{{ $t('action.reject_') }}</vs-button>
          </div>
        </div>
      </div>

      <vs-divider class="md:hidden block mt-8 mb-2"/>

      <div class="w-full md:w-4/12 lg:w-4/12 2xl:w-1/5">
        <vs-card class="shadow-drop">
          <div class="grid gap-x-1 grid-rows-3 auto-cols-min">
            <img @click="showingPhoto = true" :src="proctoringPhoto" class="row-span-3 col-span-1 cursor-pointer" width="50px;" height="50px;"/>
            <label
              class="col-start-2 row-span-1 text-sm font-bold self-end w-64 sm:w-auto md:w-48 lg:w-64 truncate"
              v-tooltip.left="{
                content: userName,
                delay: { show: 100, hide: 100 }
              }"
            >
              {{ userName }}
            </label>

            <label
              class="col-start-2 row-span-1 self-start w-64 sm:w-auto md:w-48 lg:w-64 truncate"
              v-tooltip.left="{
                content: userEmail,
                delay: { show: 100, hide: 100 }
              }"
            >
              {{ userEmail }}
            </label>
          </div>

          <div class="grid grid-cols-2">
            <span class="col-span-2">
              {{ $t('questionario') }}:
              <label class="font-bold">{{ classroomUser && classroomUser.classroom.config.questionnaire.name }}</label>
            </span>
            <span v-if="classroomUser && classroomUser.answer" class="col-span-2 md:col-span-1">
              {{ $t('inicio') }}:
              <label class="font-bold">{{ classroomUser.answer.display_created_at }}</label>
            </span>
            <span v-if="classroomUser && classroomUser.answer" class="col-span-2 md:col-span-1">
              {{ $t('fim') }}:
              <label class="font-bold">{{ $utils.format.date(classroomUser.answer.finished_at) }}</label>
            </span>
            <span v-if="classroomUser && classroomUser.audit_status" class="flex items-center col-span-2 md:col-span-1">
              {{ $t('status') }}:
              <vs-chip transparent :color="classroomUser.audit_status.color" class="text-sm text-center">{{ classroomUser.audit_status.label }}</vs-chip>
            </span>
          </div>
        </vs-card>
        <vs-tabs v-if="events.length > 0">
          <vs-tab
            :label="$t('eventos')"
            class="overflow-x-hidden overflow-y-auto"
            style="height: 470px;">
            <edu-placeholder-action v-if="noEvents" :label="$t('ainda-nao-ha-eventos')" @action="reload"/>
            <proctoring-classroom-event-list
              :events="events"
              :showFilter="true"
              :showSeverityFilter="true"
            />
          </vs-tab>
        </vs-tabs>
        <edu-placeholder-action v-else style="height:264px;" :noAction="true" :label="$t('nenhum-evento-foi-registrado')"/>
      </div>
    </div>
    <proctoring-image-modal @closed="showingPhoto=false" :src="proctoringPhoto" :visible="showingPhoto"/>
  </vx-card>
  </div>
</template>

<script>
import ProctoringClassroomEventList from './components/ProctoringClassroomEventList.vue'
import ProctoringService from '@/services/api/ProctoringService'
import Player from '../proctor/Player.vue'
import ComplexEditor from '../questionnaires/elements/ComplexEditor.vue'
import EduPlaceholderAction from '@/components/EduPlaceholderAction.vue'
import ProctoringImageModal from './components/ProctoringImageModal.vue'

export default {
  components: {
    ProctoringClassroomEventList,
    Player,
    ComplexEditor,
    EduPlaceholderAction,
    ProctoringImageModal
  },

  props: {
    id: {
      type: Number,
      default: null
    }
  },

  data: () => ({
    service: null,
    events: [],
    classroomUser: null,
    commentData: '',
    commentSeverity: 'L',
    contentSize: 0,
    showComment: false,
    showingPhoto: false,
    streamSource: null,
    options: null,
    isTimeVideo: true,
    audit_comment: null
  }),

  created() {
    this.service = ProctoringService.build(this.$vs)
    if (this.id) this.reload()
  },

  computed: {
    noEvents() {
      return this.events.length === 0
    },
    userToken() {
      return this.$store.getters.userToken
    },
    userName() {
      return this.classroomUser ? this.classroomUser.user.name : ''
    },
    userEmail() {
      return this.classroomUser ? this.classroomUser.user.email : ''
    },
    proctoringPhoto() {
      return this.classroomUser && this.classroomUser.proctoringPhoto
    }
  },

  methods: {

    reload() {
      this.fetchData(this.id)
    },

    fetchData(classroomUserId) {
      const token = this.userToken
      this.$vs.loading()
      this.service.loadClassroomUser(classroomUserId).then(
        (data) => {
          this.$vs.loading.close()
          const {events, ...classroomUser} = data[0]

          events.sort((a, b) => {
            const dateA = new Date(a.data.emitted_at)
            const dateB = new Date(b.data.emitted_at)
            if (dateA > dateB) return 1
            if (dateA < dateB) return -1
            return 0
          })

          this.audit_comment = data[0].audit_comment
          this.events = events
          this.classroomUser = classroomUser
          const answer = this.classroomUser.answer
          const contentQuestionnaireId = answer.questionnaire_id

          const video_progress = data[0].video_progress
          const fileExt = 'webm'
          const videoPreviewSufix = video_progress >= 0 && video_progress < 100 ? 'pv' : 'p'
          this.options = {
            src: `${process.env.VUE_APP_API_PROCTORING_BASE_URL}/${videoPreviewSufix}/${token}/${contentQuestionnaireId}/${answer.id}/${fileExt}`,
            type: `video/${fileExt}`,
            video_progress,
            autoplay: false,
            controls: true,
            events: this.events
          }
          console.log(this.options)
        },
        (error) => {
          this.$vs.loading.close()
          console.error(error)
        }
      )
    },
    addComment() {
      const createdAt = this.classroomUser.answer.original_created_at
      const startDate = this.$moment(createdAt, 'YYYY-MM-DD HH:mm:ss')

      const currentVideoTime = this.$refs.playerComponent.getCurrentTime()
      const eventTime = startDate.add(currentVideoTime, 'seconds')
      const formattedEventTime = eventTime.format('YYYY-MM-DD HH:mm:ss.SSS')

      const data = ({
        'type': 'comment',
        'data': {text: this.commentData},
        'severity': this.commentSeverity
      })

      if (this.isTimeVideo) {
        data.event_time = formattedEventTime
      }

      this.$vs.loading()
      this.service.addNewEvent(this.classroomUser.id, data).then((data) => {
        this.commentData = ''
        this.events.unshift(data)
        this.$vs.loading.close()
        this.notifySuccess(this.$vs, this.$t('comentario-adicionado-com-sucesso'))
      })
    },
    updateHandling(status) {
      this.$vs.loading()

      const data = {
        status: status,
        audit_comment: this.audit_comment,
      }

      this.service.registerAudit(this.id, data).then(
        data => {
          this.notifySuccess(this.$vs, this.$t('operacao-realizada-com-sucesso'))
          this.classroomUser.audit_status = data
        },
        error => {
          this.showErrors(this.$validator, error)
          this.notifyError(this.$vs,this.$t('nao-foi-possivel-realizar-esta-operacao'))
        }
      ).finally(() => { this.$vs.loading.close() })
    },
    cancelComment(){
      this.commentData = ''
      this.commentSeverity = 'L'
      this.showComment = false
    },
    editorIsOpen(){
      this.scrollToBotton()
    },
    scrollToBotton() {
      setTimeout(() => {
        window.scrollTo(0,
          window.document.documentElement.scrollTop + this.$root.$el.offsetHeight + 50)
      }, 200)
    },
    filterSeverity(severity) {
      this.commentSeverity = severity
    },
    severityFilterVisuals(filter, color = 'primary', extra = 'flex px-2 items-center cursor-pointer gap-1') {
      const borderDefinition = `border-${color} border-little-round border-shadowed`
      const colorized = this.commentSeverity === filter ? `stroke-current bg-${color} ${borderDefinition}` : ''
      return [
        colorized,
        extra
      ]
    },
  }
}
</script>
<style lang="scss">
.border-shadowed {
  border-color: transparent !important;
  box-shadow: 0 0 0 1px $black !important;
}

.border-little-round {
  border-radius: 0.2rem !important;
}
</style>
